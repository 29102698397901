import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import PrivateRoute from './privateRoute';

export const IndexPage = lazy(() => import('../pages/app'));
export const DateLists = lazy(() => import('../pages/date-lists'));
export const ErpClubDetail = lazy(() => import('../pages/erp-club-detail'));
export const ErpClubList = lazy(() => import('../pages/erp-club-list'));
export const ReservationLists = lazy(() =>
    import('../pages/reservation-lists')
);

export const ErpReservationList = lazy(() =>
    import('../pages/erp-club-reservation-list')
);

export const AutoLogin = lazy(() => import('../components/autoLogin'));

// ----------------------------------------------------------------------

export default function Router() {
    const routes = useRoutes([
        {
            path: '/',
            element: <IndexPage />,
            index: true,
        },
        {
            path: 'autologin',
            element: <AutoLogin />,
        },
        {
            path: '/discountbooking',
            element: <PrivateRoute />,
            children: [
                {
                    index: true,
                    element: <Navigate to="datelists" />,
                },
                {
                    path: 'datelists',
                    element: <DateLists />,
                },
                {
                    path: 'reservationlists',
                    element: <ReservationLists />,
                },
            ],
        },
        {
            path: '/erp',
            element: <PrivateRoute />,
            children: [
                { path: '', element: <ErpClubList /> },
                {
                    path: 'reservationlists',
                    element: <ErpReservationList />,
                },
                {
                    path: ':id',
                    element: <ErpClubDetail />,
                },
            ],
        },
        // {
        //   path: 'login',
        //   element: <LoginPage />,
        // },
        // {
        //   path: '404',
        //   element: <Page404 />,
        // },
        // {
        //   path: '*',
        //   element: <Navigate to="/404" replace />,
        // },
    ]);

    return routes;
}
