import React, { createContext, useContext } from 'react';

const StatusContext = createContext();

const statusData = [
  { value: 1, text: '예약가능', bgColor: 'gray', textColor: 'white' },
  { value: 2, text: '예약대기', bgColor: '#FFB800', textColor: 'black' },
  { value: 3, text: '예약확정', bgColor: '#14AE5C', textColor: 'white' },
  { value: 4, text: '예약취소', bgColor: 'gray', textColor: 'white' },
  { value: 5, text: '업체취소', bgColor: 'gray', textColor: 'white' },
  { value: 6, text: '기한만료', bgColor: 'gray', textColor: 'white' },
  { value: 7, text: '경기시작', bgColor: 'gray', textColor: 'white' },
  { value: 8, text: '부킹종료', bgColor: 'gray', textColor: 'white' },
];

export const StatusProvider = ({ children }) => {
  return (
    <StatusContext.Provider value={statusData}>
      {children}
    </StatusContext.Provider>
  );
};

export const useStatus = () => useContext(StatusContext);
