import { ReactNode, useEffect, useState } from 'react';

const RELOAD_CHECK = `cache-busting-vvrrffppnnhh`;

export default function CacheBuster(props) {
    const [isNeedReload, setIsNeedReload] = useState(true);

    useEffect(() => {
        const isReloadDone = window.location.search.includes(RELOAD_CHECK);
        if (isReloadDone) {
            return;
        }
        const searchParamString = window.location.search;

        const newOne = `${window.location.href}${
            searchParamString ? `&` : '?'
        }${RELOAD_CHECK}=${Date.now()}`;
        window.location.href = newOne;
    }, []);

    useEffect(() => {
        const isReloadDone = window.location.search.includes(RELOAD_CHECK);
        if (isReloadDone) {
            setIsNeedReload(false);
        }
    }, []);

    if (isNeedReload) {
        return null;
    }

    return <>{props.children}</>;
}
