import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authState } from '../recoil/authState';
import { Suspense } from 'react';
import Layout from '../layouts';

const PrivateRoute = ({ children }) => {
    const auth = useRecoilValue(authState);
    const location = useLocation();

    return auth.isLoggedIn ? (
        <Layout>
            <Suspense>
                <Outlet />
            </Suspense>
        </Layout>
    ) : (
        <Navigate
            to={`/autologin${location.search}`}
            state={{ from: location.pathname }}
        />
    );
};

export default PrivateRoute;
