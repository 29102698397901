import { Box } from '@mui/material';

// import { HEADER, NAV } from './config-layout';
// import { useResponsive } from '../hooks/use-responsive';

const SPACING = 8;

export default function Main({ children, sx, ...other }) {
    // const lgUp = useResponsive('up', 'lg');

    return (
        <Box
            component={'div'}
            // sx={{
            //     // mx: 'auto',
            //     // maxWidth: 800,
            //     display: 'flex',
            //     justifyContent: 'center',
            // }}
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                justifyContent: 'space-between',
                margin: '0 auto',
                maxWidth: '800px',
            }}
        >
            {children}
        </Box>
    );
}
